import { Gauge } from '@mui/x-charts';
import { FullHeightLayout } from '../../layouts/full-height-layout';
import { LandingStagesEnum, Review as ReviewI, reviews } from '../../types';
import './index.css';
import { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { Review } from '../review';

interface LoaderProps {
  setActiveStage: (stage: LandingStagesEnum) => void;
}

export const Loader: React.FC<LoaderProps> = ({ setActiveStage }) => {
  const [progress, setProgress] = useState<number>(0);
  const [text, setText] = useState<string>(
    'Creating your personal style guide'
  );

  useEffect(() => {
    const totalDuration = 30000; // 3 секунды
    const intervalDuration = 100; // Обновляем значение каждые 100 мс
    const totalSteps = totalDuration / intervalDuration;

    let step = 0; // Текущий шаг

    const interval = setInterval(() => {
      setProgress(() => {
        if (step >= totalSteps) {
          clearInterval(interval); // Останавливаем интервал, когда достигли конца

          setText('Your personal style guide is ready!');
          setTimeout(() => {
            setActiveStage(LandingStagesEnum.EMAIL);
          }, 2000);

          return 100;
        }

        // Используем квадратичную функцию (x^2) для нелинейного изменения
        const progressValue =
          Math.sin((step / totalSteps) * (Math.PI / 2)) * 100;

        step += 1;
        return progressValue;
      });
    }, intervalDuration);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(interval);
  }, [setActiveStage]);

  return (
    <FullHeightLayout>
      <div className='loader__container'>
        <div className='loader__content'>
          <Gauge
            width={132}
            height={132}
            value={Math.floor(progress)}
            text={({ value }) => `${value} %`}
            innerRadius='92%'
            sx={() => ({
              [`& .MuiGauge-valueArc`]: {
                fill: '#ff8c80',
              },
              [`& .MuiGauge-valueText`]: {
                fontSize: 32,
                fontFamily: 'Montserrat',
                fontWeight: 600,
              },
            })}
          />
          <div className='loader__text-1'>{text}</div>
          <div className='loader__title title'>150 million people</div>
          <div className='loader__text-2'>have chosen AmazeYou</div>
          <Carousel
            className='carousel'
            autoplay={true}
            autoplaySpeed={3000}
            speed={1000}
            dots={false}
          >
            {reviews.map((review: ReviewI, index: number) => (
              <div className='review__container' key={index}>
                <Review
                  name={review.name}
                  label={review.label}
                  date={review.date}
                  text={review.text}
                ></Review>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </FullHeightLayout>
  );
};
