import { useEffect, useState } from 'react';
import { FullHeightLayout } from '../../layouts/full-height-layout';
import { LandingStagesEnum } from '../../types';
import { Button } from '../button';
import './index.css';
import { Input } from 'antd';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../../firebase';

interface EmailProps {
  setActiveStage: (stage: LandingStagesEnum) => void;
}

export const Email: React.FC<EmailProps> = ({ setActiveStage }) => {
  const [email, setEmail] = useState<string>();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email) {
      setIsEmailValid(emailRegex.test(email));
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  const addEmailHandler = async () => {
    try {
      setIsLoading(true);
      // Сохраняем email в коллекцию 'emails' в Firestore
      await addDoc(collection(db, 'emails'), {
        email: email,
        createdAt: new Date(),
      });

      setEmail(''); // Очищаем поле email после успешного сохранения
      setIsLoading(false);
      setActiveStage(LandingStagesEnum.NAME);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <FullHeightLayout>
      <div className='email__container'>
        <div className='email__content'>
          <div className='email__title title'>
            Enter your email to get your{' '}
            <span className='text-accent'>Personal Style Guide</span>
          </div>
          <Input
            className='email__input'
            placeholder='Your email'
            size='large'
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <Button
          label='continue'
          disabled={!isEmailValid || isLoading}
          callback={() => addEmailHandler()}
        ></Button>
      </div>
    </FullHeightLayout>
  );
};
