import './index.css';
import goalIcon from '../../../../../../assets/icons/goal-icon.svg';
import tShortIcon from '../../../../../../assets/icons/t-short-icon.svg';
import rightArrowIcon from '../../../../../../assets/icons/right-arrow-icon.svg';
import selectedItemIcon from '../../../../../../assets/icons/selected-icon.svg';
import { Button } from '../../../button';
import { useNavigate } from 'react-router-dom';

interface Option {
  name: string;
  fullPriceBefore: string;
  fullPriceAfter: string;
  dailyPriceBefore: string;
  dailyPriceAfter: string;
}

interface PlanOptionsProps {
  selectedItem: number;
  setSelecteditem: (value: number) => void;
}

const options: Option[] = [
  {
    name: '1-Week Trial',
    fullPriceBefore: '€14.14',
    fullPriceAfter: '€6.93',
    dailyPriceBefore: '€2.02',
    dailyPriceAfter: '€0.99',
  },
  {
    name: '4-Week Plan',
    fullPriceBefore: '€30.99',
    fullPriceAfter: '€15.19',
    dailyPriceBefore: '€1.11',
    dailyPriceAfter: '€0.54',
  },
  {
    name: '12-Week Plan',
    fullPriceBefore: '€53.04',
    fullPriceAfter: '€25.99',
    dailyPriceBefore: '€0.63',
    dailyPriceAfter: '€0.31',
  },
];

export const PlanOptions: React.FC<PlanOptionsProps> = ({
  selectedItem,
  setSelecteditem,
}) => {
  const navigate = useNavigate();

  return (
    <div className='plan-options__container'>
      <div className='plan-options__title title'>
        Your Personal Style Guide is Ready!
      </div>

      <div className='plan-options__benefit benefit'>
        <img src={tShortIcon} alt='t-short' />
        <div className='benefit__info'>
          <div className='benefit__name'>Current style</div>
          <div className='benefit__value'>Casual and simple</div>
        </div>
      </div>

      <div className='plan-options__benefit benefit'>
        <img src={goalIcon} alt='t-short' />
        <div className='benefit__info'>
          <div className='benefit__name'>Your goal</div>
          <div className='benefit__value'>Fashion-forward and personalized</div>
        </div>
      </div>

      <div className='plan-options__options'>
        {options.map((option: Option, index: number) => (
          <div
            className={`plan-options__option option ${
              index === selectedItem ? 'selected' : ''
            }`}
            key={index}
            onClick={() => setSelecteditem(index)}
          >
            <div className='option__main'>
              {index === selectedItem ? (
                <img src={selectedItemIcon} alt='selected' />
              ) : (
                <div className='option__checkbox'></div>
              )}

              <div className='option__info'>
                <div className='option__name'>{option.name}</div>
                <div className='option__discount'>
                  <span className='option__discount-old-price'>
                    {option.fullPriceBefore}
                  </span>
                  <img src={rightArrowIcon} alt='>' />
                  <span className='option__discount-new-price'>
                    {option.fullPriceAfter}
                  </span>
                </div>
              </div>
            </div>

            <div className='option__price'>
              <div className='option__price-old-price'>
                {option.dailyPriceBefore}
              </div>
              <div className='option__price-new-price'>
                {option.dailyPriceAfter}
              </div>
              per day
            </div>
          </div>
        ))}
      </div>

      <Button label='Get my plan' callback={() => navigate('/thanks')}></Button>

      <div className='plan-options__disclamer'>
        Without cancellation, before the selected discounted intro plan ends, I
        accept that AmazeYou will automatically charge €30.99 (excl. VAT) every
        4 weeks until I cancel. Taxes calculated at checkout. Cancel online via
        the account page on the website or app.
      </div>
    </div>
  );
};
