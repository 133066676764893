import { useState } from 'react';
import HeaderWithTimer from './components/header-with-timer';
import { Highlights } from './components/highlights';
import { PhoneScreen } from './components/phone-screen';
import { PlanOptions } from './components/plan-options';
import { Reviews } from './components/reviews';
import './index.css';
import { BeforeAfter } from './components/before-after';
import { FAQ } from './components/faq';

interface MainProps {
  fashionLevel: number;
  currentStyle: number;
  styleGoal: number;
}

export const Main: React.FC<MainProps> = ({
  fashionLevel,
  currentStyle,
  styleGoal,
}) => {
  const [selectedItem, setSelecteditem] = useState<number>(1);

  return (
    <div className='main__container'>
      <HeaderWithTimer></HeaderWithTimer>
      <BeforeAfter
        fashionLevel={fashionLevel}
        currentStyle={currentStyle}
        styleGoal={styleGoal}
      ></BeforeAfter>
      <PlanOptions
        selectedItem={selectedItem}
        setSelecteditem={setSelecteditem}
      ></PlanOptions>
      <Highlights></Highlights>
      <PhoneScreen></PhoneScreen>
      <FAQ></FAQ>
      <Reviews></Reviews>
      <PlanOptions
        selectedItem={selectedItem}
        setSelecteditem={setSelecteditem}
      ></PlanOptions>
    </div>
  );
};
